<script setup>

import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { Head, usePage } from '@inertiajs/vue3'
import * as Sentry from '@sentry/vue'
import { ref } from 'vue'

const page = usePage()
const localeAndLinks = ref([])

const queryParamsToAdd = {}
const hasPageQuery = page.props.route?.query?.page
if (hasPageQuery) {
  queryParamsToAdd.page = page.props.route.query.page
}

const locales = page.props.routeLocales || []
const routeName = page.props.route?.name
const routeParams = page.props.route?.params
const localeOverrides = page.props.routeParamLocaleOverrides

const altLinks = page.props.altLinks

const canGenerateAlternates = routeName != null && routeParams != null && locales.length > 0 && localeOverrides != null
if (!altLinks) {
  if (canGenerateAlternates) {
    localeAndLinks.value = locales.map((locale) => {
      const overrides = localeOverrides[locale] || {}
      const href = getLocalizedUrl(routeName, {
        ...routeParams,
        ...overrides
      }, queryParamsToAdd, locale)

      return {
        hreflang: locale,
        href
      }
    })
  } else {
    const message = 'could not generate alternate links, are the props properly set?'
    const hint = {
      locales,
      props: page.props.route,
      overrides: { ...localeOverrides }
    }

    // it might just be a 404, so lets not capture those
    const isLikely404 = routeName == null
    const isLikelyPageWithoutlocales = locales.length === 0

    if (!(isLikely404 || isLikelyPageWithoutlocales)) {
      Sentry.captureException(new Error(message), hint)
    }
  }
}
</script>

<template>
  <Head v-if="altLinks != null">
    <link v-for="locale in Object.keys(altLinks)" :key="locale" rel="alternate" :hreflang="locale" :href="altLinks[locale].href">
  </Head>
  <Head v-else>
    <link
      v-for="locale in localeAndLinks"
      :key="locale.hreflang"
      rel="alternate"
      :hreflang="locale.hreflang"
      :href="locale.href"
    >
  </Head>
</template>
